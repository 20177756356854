exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-contentful-blogs-js": () => import("./../../../src/pages/blogs/contentful-blogs.js" /* webpackChunkName: "component---src-pages-blogs-contentful-blogs-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-contentful-events-js": () => import("./../../../src/pages/events/contentful-events.js" /* webpackChunkName: "component---src-pages-events-contentful-events-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-services-enlightened-heart-retreat-js": () => import("./../../../src/pages/services/enlightened-heart-retreat.js" /* webpackChunkName: "component---src-pages-services-enlightened-heart-retreat-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-practitioner-training-js": () => import("./../../../src/pages/services/practitioner-training.js" /* webpackChunkName: "component---src-pages-services-practitioner-training-js" */),
  "component---src-pages-services-weddings-js": () => import("./../../../src/pages/services/weddings.js" /* webpackChunkName: "component---src-pages-services-weddings-js" */),
  "component---src-pages-services-yoga-retreats-js": () => import("./../../../src/pages/services/yoga-retreats.js" /* webpackChunkName: "component---src-pages-services-yoga-retreats-js" */),
  "component---src-pages-suites-js": () => import("./../../../src/pages/suites.js" /* webpackChunkName: "component---src-pages-suites-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

